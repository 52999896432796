<script setup lang="ts">
import { Operation } from "@element-plus/icons-vue";
import { UserRoles } from "@/shared/enums";
const { t } = useI18n();
const { data: user } = useAuth();
const adminButton = computed(() => {
  return (
    user.value?.role === UserRoles.Admin ||
    user.value?.role === UserRoles.Moderator
  );
});
const UseInitStore = useInitStore();
const { initData } = storeToRefs(UseInitStore);
const UseLocalStore = useLocalStore();
const { cookiesSettings, tfaAlert } = storeToRefs(UseLocalStore);
const showCookiePopup = ref(false);
const showTFAPopup = ref(false);

onBeforeMount(() => {
  if (!cookiesSettings.value.settedUp && initData.value?.continent === "EU") {
    showCookiePopup.value = true;
  }
  watch(
    user,
    () => {
      if (!user.value) return;
      if (
        !user.value.tfaEnabled &&
        (!tfaAlert.value.date ||
          Date.now() - tfaAlert.value.date > 1000 * 60 * 60 * 24 ||
          tfaAlert.value.userId !== user.value.userId)
      ) {
        showTFAPopup.value = true;
      }
    },
    { immediate: true }
  );
});
const localePath = useLocalePath();
</script>

<template>
  <div class="default-width text-center">
    <ul class="footer-menu">
      <li>
        <NuxtLink class="no-underline" :to="localePath('/trade')">{{
          t("c-footer-menu-trade")
        }}</NuxtLink>
      </li>
      <li>
        <NuxtLink class="no-underline" :to="localePath('/news')">{{
          t("c-footer-menu-news")
        }}</NuxtLink>
      </li>
      <li>
        <NuxtLink class="no-underline" :to="localePath('/blog')">{{
          t("c-footer-menu-blog")
        }}</NuxtLink>
      </li>

      <li>
        <NuxtLink class="no-underline" :to="localePath('/faq')">{{
          t("c-footer-menu-faq")
        }}</NuxtLink>
      </li>
      <li>
        <NuxtLink class="no-underline" :to="localePath('/beginners-guide')">{{
          t("c-footer-menu-beginnersGuide")
        }}</NuxtLink>
      </li>
      <li>
        <NuxtLink class="no-underline" :to="localePath('/about')">{{
          t("c-footer-menu-about")
        }}</NuxtLink>
      </li>
      <li>
        <span
          class="cursor-pointer opacity-35 decoration-underline"
          @click="showCookiePopup = true"
        >
          {{ t("c-footer-menu-manageCookies") }}
        </span>
      </li>
    </ul>
    <div class="divider-small">
      <el-divider />
    </div>
    <div class="admin-button" v-if="adminButton">
      <NuxtLink :to="localePath('/administration')">
        <el-button type="danger" :icon="Operation" size="small" round>
          {{ t("c-footer-menu-adminPanel") }}
        </el-button>
      </NuxtLink>
    </div>
    <UserTFAAlert v-model:dialogVisible="showTFAPopup" />
    <UserCookieAlert v-model:dialogVisible="showCookiePopup" />
  </div>
</template>

<style lang="scss" scoped>
.footer-menu {
  padding: 0;
  &::after {
    content: "";
    clear: both;
    float: none;
    display: block;
  }
  li {
    display: inline-block;
    min-height: 30px;
    line-height: 30px;
    position: relative;
    a {
      color: var(--el-color-primary);
      line-height: 1;
      display: inline-block;
    }
    @media (max-width: 988px) {
      display: block;
      float: left;
      width: 50%;
      box-sizing: border-box;

      &:nth-child(2n - 1) {
        &::before {
          display: none;
        }
      }
    }
  }
  li + li {
    margin-left: 40px;
    @media (max-width: 988px) {
      margin-left: 0;
    }

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      height: 100%;
      width: 1px;
      background: var(--el-border-color-darker);
      @media (max-width: 988px) {
        left: 0;
      }
    }
  }
}
.admin-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 2001;
}
</style>
