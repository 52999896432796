<template>
  <div class="text-center">
    <a
      class="mr-1"
      href="https://wellcrypto.io/ru/exchangers/"
      title="Сервис поиска выгодного курса обмена"
      target="_blank"
      ><img
        src="https://wellcrypto.io/i/b_1.svg"
        width="88"
        height="31"
        border="0"
    /></a>
    <a
      class="mr-1"
      href="https://exchangesumo.com"
      title="Сервис поиска выгодного курса обмена"
      target="_blank"
      ><img
        src="https://b.exchangesumo.com/i/1.svg"
        width="88"
        height="31"
        border="0"
    /></a>
    <a
      class="mr-1"
      href="https://kurs.expert"
      title="Обмен Биткоин, Киви, Яндекс, AdvCash"
      target="_blank"
      ><img
        src="https://kurs.expert/i/btn.cb.png"
        width="100"
        height="31"
        border="0"
    /></a>
    <a
      class="mr-1"
      href="https://kurs.com.ru/wm"
      title="Мониторинг обменников электронных валют"
      target="_blank"
      ><img
        src="https://kurs.com.ru/storage/images/monitoring/banners/88x31.png"
        width="88"
        height="31"
        alt="kurs.com.ua"
    /></a>
    <a class="mr-1" href="https://pro-obmen.ru" target="_blank"
      ><img
        alt="Мониторинг обменников Pro-Obmen.ru"
        src="https://pro-obmen.ru/img/bn/88x31_1.gif"
        title="Мониторинг обменников Pro-Obmen.ru"
    /></a>
    <a
      class="mr-1"
      href="https://bestexchangers.ru/"
      target="_blank"
      title="Мониторинг обменников BestExchangers.ru"
      ><img
        src="https://bestexchangers.ru/images/88x31_2.gif"
        border="0"
        alt="BestExchangers.ru"
      />
    </a>
  </div>
</template>
